import { ParticleOneCreateSpaceRequest, ParticleOneMaskType, ParticleOneTestingProgram, ParticleOneUnit} from '@/network/models/ParticleOneCreateSpaceRequest';
import { isNullUndefinedOrEmpty } from './create-building';

type RequiredMapper = (form: any, field: string) => boolean
type ValidationMapper = (form: any, name: string) => string | null
type IsValidMapper = (form: any, name: string) => boolean

interface FieldMapping {
  name: string
  apiField?: string
  required(form: any): boolean
  isValid(form: any): boolean
  validation(form: any, name: string): string | null
  isErrored(form: any, name: string): boolean
}

class FormField implements FieldMapping {
  name: string
  apiField?: string
  requiredMapping: RequiredMapper
  validationMapping?: ValidationMapper
  isValidMapping: IsValidMapper

  constructor(name: string, apiField: string, requiredMapping: RequiredMapper, isValidMapping: IsValidMapper, validationMapping?: ValidationMapper) {
    this.name = name;
    this.apiField = apiField;
    this.requiredMapping = requiredMapping;
    this.validationMapping = validationMapping;
    this.isValidMapping = isValidMapping;
  }

  required(form: any): boolean {
    return this.requiredMapping(form, this.name);
  }

  validation(form: any, name: string): string | null {
    if (this.validationMapping) {
      return this.validationMapping(form, name);
    }
    return null;
  }

  isValid(form: any): boolean {
    return this.isValidMapping(form, this.name);
  }

  /**
   * Determines if the field value entered resulted in an error. 
   *
   * @remarks
   * If the entered value is _valid_ but some validation message was produced;
   * **warning**, not an error. These messages should be displayed differently
   * than erorrs: entered value is _not valid_.
   * 
   * This function does not simply call the existing `isValid()` function since
   * it uses a custom `name` that is passed in.
   *
   * @param form - The current space form
   * @param name - The name of the field in `form`
   * @returns `true` if the field value has resulted in an error, `false` otherwise.
   *
   */
  isErrored(form: any, name: string): boolean {
    if (!this.isValidMapping(form, name)) return true;
    return false;
  }
}

function returnTrue() {
  return true;
}

function returnMaskUse(form: any) {
  return form['maskType'] != ParticleOneMaskType.None;
}

function returnBackgroundVaccinationRate(form: any) {
  return !form['vaccineMandate'];
}

function returnBackgroundVaccinationRateFlu(form: any) {
  return !form['vaccineMandateFlu'];
}

function returnBackgroundVaccinationRateBooster(form: any) {
  return !form['vaccineMandateFlu'];
}

function returnVaccinationPercent(form: any) {
  return form['backgroundVaccinationRate'] === 'custom';
}

function returnVaccinationPercentFlu(form: any) {
  return form['backgroundVaccinationRateFlu'] === 'custom';
}

function returnVaccinationPercentBooster(form: any) {
  return form['backgroundVaccinationRateBooster'] === 'custom';
}

function returnTestingProgram(form: any) {
  return form['testingProgram'];
}

function returnTestingProgramType(form: any) {
  // testing cadence is optional for testing program type of "One-time"
  return form['testingProgramType'] && form['testingProgramType'] !== ParticleOneTestingProgram['One-time'];
}

function returnSupplyAirflowRate(form: any) {
  return form['supplyOa'] === 'custom';
}

function returnTotalCleanAirDeliveryRate(form: any) {
  return form['airCleaningDevices'];
}

function nonEmptyString(form: any, name: string) {
  const value = form['name'];
  if (typeof value === 'undefined') {
    return null;
  }
  return value.length > 0 ? null : 'Please enter a value.';
}

function validNumber(form: any, name: string) {
  const value = form[name];
  if (typeof value === 'undefined' || value == null) {
    return null;
  }
  if (value.length <= 0) {
    return 'Please enter a numerical value.';
  }
  if (isNaN(Number(value))) {
    return 'Please enter a valid numerical value.';
  }
  return Number(value) > 0 ? null : 'Please enter a valid numerical value.';
}

function validPercent(form: any, name: string) {
  const value = form[name];
  if (typeof value === 'undefined' || value == null) {
    return null;
  }
  if (value.length <= 0) {
    return 'Please enter a percent.';
  }
  if (isNaN(Number(value))) {
    return 'Please enter a percent.';
  }
  return Number(value) > 0 && Number(value) <= 100 ? null : 'Please enter a valid percent.';
}

function validRelativeHumidity(form: any, name: string) {
  const value = form[name];
  if (typeof value === 'undefined' || value == null) {
    return null;
  }
  if (value.length <= 0) {
    return 'Please enter a percent.';
  }
  if (isNaN(Number(value))) {
    return 'Please enter a percent.';
  }
  return Number(value) >= 20 && Number(value) <= 70 ? null : 'Enter a value between 20% and 70%';
}

function containsValidString(form: any, name: string) {
  return !isNullUndefinedOrEmpty(form[name]);
}

function containsValidNumber(form: any, name: string) {
  const value = form[name];
  if (typeof value === 'undefined' || value == null) {
    return false;
  }
  if (value.length <= 0) {
    return false;
  }
  if (isNaN(Number(value))) {
    return false;
  }
  return Number(value) > 0;
}

function containsValidPercent(form: any, name: string) {
  const value = form[name];
  if (typeof value === 'undefined' || value == null) {
    return false;
  }
  if (value.length <= 0) {
    return false;
  }
  if (isNaN(Number(value))) {
    return false;
  }
  return Number(value) > 0 && Number(value) <= 100;
}

function containsValidRelativeHumidity(form: any, name: string) {
  const value = form[name];
  if (typeof value === 'undefined' || value == null) {
    return false;
  }
  if (value.length <= 0) {
    return false;
  }
  if (isNaN(Number(value))) {
    return false;
  }
  return Number(value) >= 20 && Number(value) <= 70;
}

function validFloorArea(form: any, name: string) {
  const value = form[name];
  const units = form['units'];
  const isElevator = form['sub_category'] === 'elevator';

  if (typeof value === 'undefined' || value == null) {
    return null;
  }
  const res = validNumber(form, name);
  if (res !== null) return res;

  const minImperial = isElevator ? 12 : 15;
  const maxImperial = isElevator ? 100 : 100000;
  const minMetric = isElevator ? 1.12 : 1.35;
  const maxMetric = isElevator ? 9.29 : 9290.3;

  
  if (units === ParticleOneUnit.Imperial) {
    return Number(value) >= minImperial && Number(value) <= maxImperial ? null : produceRangeError('Floor Area', minImperial, maxImperial, 'sq ft');
  }
  return Number(value) >= minMetric && Number(value) <= maxMetric ? null : produceRangeError('Floor Area', minMetric, maxMetric, 'm2');
}

function validPeakOccupancy(form: any, name: string) {
  const value = form[name];
  const units = form['units'];
  const subCategory = form['sub_category'];
  const floorArea = form['floor_area'];

  if (typeof value === 'undefined' || value == null) {
    return null;
  }
  const res = validNumber(form, name);
  if (res !== null) return res;
  
  const density = floorArea / Number(value);
  let lowerLimit, upperLimit;
  if (subCategory === 'elevator') {
    if (units === ParticleOneUnit.Imperial) {
      lowerLimit = 1;
      upperLimit = 20;
    } else {
      lowerLimit = 0.092903;
      upperLimit = 1.85806;
    }
  } else {
    if (units === ParticleOneUnit.Imperial) {
      lowerLimit = 25;
      upperLimit = 6000;
    } else {
      lowerLimit = 2.3;
      upperLimit = 557;
    }
  }
  return density >= lowerLimit && density <= upperLimit ? null : 'The number of people in this space is unusual for its size. Please review before proceeding.';
}

function containsValidTemperature(form: any, name: string): boolean {
  const { [name]: temperature, units } = form;
  return containsValidNumber(form, name) &&
    units === ParticleOneUnit.Imperial ?
    Number(temperature) >= 32 && Number(temperature) <= 122 :
    Number(temperature) >= 0 && Number(temperature) <= 50;
}

function validTemperature(form: any, name: string) {
  const { [name]: temperature, units } = form;
  if (!temperature) {
    return null;
  }
  const res = validNumber(form, name);
  if (res !== null) return res;

  if (units === ParticleOneUnit.Imperial) {
    return Number(temperature) >= 32 && Number(temperature) <= 122 ? null : produceRangeError('temperature', 32, 122, '˚F');
  }
  return Number(temperature) >= 0 && Number(temperature) <= 50 ? null : produceRangeError('temperature', 0, 50, '˚C');
}

function validSupplyAirflowRate(form: any, name: string) {
  const supplyAirflowRate = form[name];
  const units = form['units'];
  const ceilingHeight = form['ceiling_height'];
  const floorArea = form['floor_area'];

  const res = validNumber(form, name);
  if (res !== null) return res;

  const mech_ach = units === ParticleOneUnit.Imperial ? supplyAirflowRate * 60 / (floorArea * ceilingHeight) : supplyAirflowRate * 3600 / (floorArea * ceilingHeight * 1000);
  return mech_ach <= 0.5 || mech_ach >= 12 ? 'The supply airflow rate is unusual for a space of this size. Please review before proceeding.' : null;

}

function validTotalCleanAirDeliveryRate(form: any, name: string) {
  if (form['air_cleaning_devices'] === false) return null;
  const units = form['units'];
  const totalCleanAirDeliveryRate = form['total_clean_air_delivery_rate'];
  const ceilingHeight = form['ceiling_height'];
  const floorArea = form['floor_area'];

  const res = validNumber(form, name);
  if (res !== null) return res;

  const additional_ach = units === ParticleOneUnit.Imperial ? totalCleanAirDeliveryRate * 60 / (floorArea * ceilingHeight) : totalCleanAirDeliveryRate * 3600 / (floorArea * ceilingHeight * 1000);
  return additional_ach <= 0 || additional_ach >= 8 ? 'The total clean air delivery rate is unusual for a space of this size. Please review before proceeding.' : null;
}

function containsValidBackgroundVaccinationRate(form: any) {
  if (returnBackgroundVaccinationRate(form)) {
    return form['backgroundVaccinationRate'] === 'custom' ? containsValidPercent(form, 'vaccinationPercent') : true;
  }
  return true;
}

function containsValidBackgroundVaccinationRateBooster(form: any) {
  if (returnBackgroundVaccinationRateBooster(form)) {
    return form['backgroundVaccinationRateBooster'] === 'custom' ? containsValidPercent(form, 'vaccinationPercentBooster') : true;
  }
  return true;
}

function containsValidBackgroundVaccinationRateFlu(form: any) {
  if (returnBackgroundVaccinationRateFlu(form)) {
    return form['backgroundVaccinationRateFlu'] === 'custom' ? containsValidPercent(form, 'vaccinationPercentFlu') : true;
  }
  return true;
}

export function createFormData(response: ParticleOneCreateSpaceRequest): any {
  return FORM_FIELD_PAGES
    .flatMap(field => field.map(field => (formDataMapping(field.name, response, field.apiField))))
    .reduce((object, value) => { return { ...object, ...value }; }, {});
}

export function produceRangeError(field: string, lowerBound: number, upperBound: number, unit: string) {
  return `${field.charAt(0).toUpperCase() + field.slice(1)} typically falls between ${lowerBound.toLocaleString()}${unit} - ${upperBound.toLocaleString()}${unit} for most indoor spaces.`;
}

function formDataMapping(field: string, response: ParticleOneCreateSpaceRequest, apiField?: string): { [key: string]: any } {
  if (field === 'supplyOa') {
    return { [field]: (response.supply_airflow_rate == null && response.outside_air_rate == null) ? 'default' : 'custom' };
  }
  if (field === 'backgroundVaccinationRate') {
    return { [field]: (response.background_vaccination_rate == null || response.background_vaccination_rate === 100) ? 'default' : 'custom' };
  }
  if (field === 'backgroundVaccinationRateBooster') {
    return { [field]: (response.background_vaccination_rate_booster == null || response.background_vaccination_rate_booster === 100) ? 'default' : 'custom' };
  }
  if (field === 'backgroundVaccinationRateFlu') {
    return { [field]: (response.background_vaccination_rate_flu == null || response.background_vaccination_rate_flu === 100) ? 'default' : 'custom' };
  }
  return { [field]: Object(response)[apiField ?? ''] };
}

export const FORM_FIELD_PAGES: FormField[][] = [
  [
    new FormField('name', 'name', returnTrue, containsValidString, nonEmptyString),
    new FormField('description', 'description', returnTrue, returnTrue)
  ],
  [
    new FormField('spaceType', 'category', returnTrue, containsValidString),
    new FormField('subcategory', 'sub_category', returnTrue, containsValidString),
    new FormField('units', 'units', returnTrue, containsValidString),
    new FormField('floorArea', 'floor_area', returnTrue, containsValidNumber, validFloorArea),
    new FormField('ceilingHeight', 'ceiling_height', returnTrue, containsValidNumber, validNumber),
  ],
  [
    new FormField('peakOccupancy', 'peak_occupancy', returnTrue, containsValidNumber, validPeakOccupancy),
    new FormField('occupantDurationMinutes', 'occupant_duration_minutes', returnTrue, containsValidString),
    new FormField('occupantSchedule', 'occupant_schedule', returnTrue, containsValidString),
    new FormField('occupantProfile', 'occupant_profile', returnTrue, containsValidString),
    new FormField('activity', 'activity', returnTrue, containsValidString),
    new FormField('maskType', 'mask_type', returnTrue, containsValidString),
    new FormField('maskUse', 'mask_use_rate', returnMaskUse, containsValidPercent, validPercent), // change form key
    new FormField('socialDistancing', 'social_distancing', returnTrue, containsValidString),
    new FormField('vaccineMandate', 'vaccine_mandate', returnTrue, returnTrue),
    new FormField('backgroundVaccinationRate', /* ignored */'background_vaccination_rate', returnBackgroundVaccinationRate, containsValidBackgroundVaccinationRate), // set to 100% if true
    new FormField('vaccinationPercent', 'background_vaccination_rate', returnVaccinationPercent, containsValidPercent, validPercent),
    new FormField('vaccineMandateBooster', 'vaccine_mandate_booster', returnTrue, returnTrue),
    new FormField('backgroundVaccinationRateBooster', /* ignored */'background_vaccination_rate_booster', returnBackgroundVaccinationRateBooster, containsValidBackgroundVaccinationRateBooster), // set to 100% if true
    new FormField('vaccinationPercentBooster', 'background_vaccination_rate_booster', returnVaccinationPercentBooster, containsValidPercent, validPercent),
    new FormField('vaccineMandateFlu', 'vaccine_mandate_flu', returnTrue, returnTrue),
    new FormField('backgroundVaccinationRateFlu', /* ignored */'background_vaccination_rate_flu', returnBackgroundVaccinationRateFlu, containsValidBackgroundVaccinationRateFlu), // set to 100% if true
    new FormField('vaccinationPercentFlu', 'background_vaccination_rate_flu', returnVaccinationPercentFlu, containsValidPercent, validPercent),
    new FormField('testingProgram', 'testing_program', returnTrue, returnTrue),
    new FormField('enhancedCleaning', 'enhanced_cleaning', returnTrue, returnTrue),
    new FormField('testingTechnology', 'testing_technology', returnTestingProgram, containsValidString),
    new FormField('testingProgramType', 'program', returnTestingProgram, containsValidString),
    new FormField('testingCadence', 'testing_cadence', returnTestingProgramType, containsValidString),
    new FormField('testingUnvaccinated', 'testing_unvaccinated_only', returnTestingProgram, containsValidString),
  ],
  [
    new FormField('supplyAirflowRate', 'supply_airflow_rate', returnSupplyAirflowRate, containsValidNumber, validSupplyAirflowRate),
    new FormField('outsideAirRate', 'outside_air_rate', returnSupplyAirflowRate, containsValidNumber, validNumber),
    new FormField('mechanicalSystem', 'mechanical_system', returnTrue, containsValidString),
    new FormField('filtration', 'filtration', returnTrue, containsValidString),
    new FormField('temperature', 'temperature', returnTrue, containsValidTemperature, validTemperature),
    new FormField('relativeHumidityRate', 'relative_humidity_rate', returnTrue, containsValidRelativeHumidity, validRelativeHumidity),
    new FormField('barriers', 'barriers', returnTrue, returnTrue),
    new FormField('airCleaningDevices', 'air_cleaning_devices', returnTrue, returnTrue),
    new FormField('totalCleanAirDeliveryRate', 'total_clean_air_delivery_rate', returnTotalCleanAirDeliveryRate, containsValidNumber, validTotalCleanAirDeliveryRate),
    new FormField('upperRoomUvgi', 'upper_room_uvgi', returnTrue, returnTrue),
  ],
  []
];

export {
  FormField
};
